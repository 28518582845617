import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const TrackForm = () => {
  return (
    <>
      <Formik
        initialValues={{
          policyNo: "",
          postCode: "",
        }}
        validationSchema={Yup.object({
          policyNo: Yup.string().required("Required"),
          postCode: Yup.string()
            .max(9, "Please enter a valid postcode")
            .required("A Post Code is Required"),
        })}
        onSubmit={(values) => {
          window.location.href = `/#/jobs/${values.policyNo}/${values.postCode}`;
        }}
      >
        <Form style={{ textAlign: "left", display: "inline-block" }}>
          <fieldset className="row">
            <div className="col-12 col-md-8">
              <label htmlFor="policyNo">Job No. (*)</label>
              <Field
                name="policyNo"
                id="policyNo"
                className="form-control"
                placeholder=""
                type="text"
                aria-required
                required
                enterKeyHint="next"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="policyNo"
              />
            </div>
            <div className="col-12 col-md-8">
              <label htmlFor="postCode">Post Code (*)</label>
              <Field
                name="postCode"
                id="postCode"
                className="form-control"
                placeholder=""
                type="text"
                required
                enterKeyHint="done"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="postCode"
              />
            </div>
          </fieldset>

          <button
            className="mt-3 mb-3"
            style={{ minWidth: "10rem" }}
            type="submit"
          >
            Go
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default TrackForm;
