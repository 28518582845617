import React from "react";

// function press(data, next, manufacturer) {
//     // data.manufacturer = manufacturer
//     // next()
//     console.log(manufacturer; next()
// }

function warrantyCameraManufacturers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "LUMIX";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          LUMIX
        </button>
        <button
          onClick={() => {
            data.manufacturer = "OTHER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Other
        </button>
      </div>
    </>
  );
}

function chargeableCameraManufacturers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "LUMIX";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          LUMIX
        </button>
        <button
          onClick={() => {
            data.manufacturer = "CANON";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Canon
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "NIKON";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Nikon
        </button>
        <button
          onClick={() => {
            data.manufacturer = "FUJIFILM";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Fujifilm
        </button>
        <button
          onClick={() => {
            data.manufacturer = "GOPRO";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          GoPro
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SIGMA";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sigma
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "TAMRON";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Tamron
        </button>
        <button
          onClick={() => {
            data.manufacturer = "PENTAX";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Pentax
        </button>
        <button
          onClick={() => {
            data.manufacturer = "VOIGTLANDER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Voigtlander
        </button>
        <button
          onClick={() => {
            data.manufacturer = "OLYMPUS";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Olympus
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "OTHER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Other
        </button>
      </div>
    </>
  );
}

function warrantyCamcorderManufacturers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "OTHER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Other
        </button>
      </div>
    </>
  );
}

function chargeableCamcorderManufactuers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "JVC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          JVC
        </button>
        <button
          onClick={() => {
            data.manufacturer = "CANON";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Canon
        </button>
        <button
          onClick={() => {
            data.manufacturer = "OTHER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Other
        </button>
      </div>
    </>
  );
}

function warrantyAudioManufactuers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
      </div>
    </>
  );
}

function chargeableAudioManufacturers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Panasonic
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SAMSUNG";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Samsung
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "LG";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          LG
        </button>
        <button
          onClick={() => {
            data.manufacturer = "PIONEER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Pioneer
        </button>
        <button
          onClick={() => {
            data.manufacturer = "TECHNICS";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Technics
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "OTHER";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Other
        </button>
      </div>
    </>
  );
}

function chargeableHeadphoneManufactuers(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "PANASONIC";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sony
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Beats By Dre
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Sennheiser
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Bose
        </button>
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
          className="col-12 col-sm-3 m-2"
        >
          Technics
        </button>
      </div>
    </>
  );
}

function warrantyTVManufactuers(data, next) {}

function chargeableTVManufactuers(data, next) {}

function otherManufacturer(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.manufacturer = "SONY";
            next();
          }}
        >
          OTHER
        </button>
      </div>
    </>
  );
}

function Manufacturer(props) {
  const { data, next, previous } = props;

  switch (data.productType) {
    case "Audio":
      return (
        <>
          {data.warranty
            ? warrantyAudioManufactuers(data, next)
            : chargeableAudioManufacturers(data, next)}
          <button onClick={previous}>Previous</button>
        </>
      );
    case "Camcorder":
      return (
        <>
          {data.warranty
            ? warrantyCamcorderManufacturers(data, next)
            : chargeableCamcorderManufactuers(data, next)}
          <button onClick={previous}>Previous</button>
        </>
      );
    case "Camera":
      return (
        <>
          {data.warranty
            ? warrantyCameraManufacturers(data, next)
            : chargeableCameraManufacturers(data, next)}
          <button onClick={previous}>Previous</button>
        </>
      );
    case "Headphones":
      return (
        <>
          {chargeableHeadphoneManufactuers()}
          <button onClick={previous}>Previous</button>
        </>
      );
    case "TV":
      return (
        <>
          {data.warranty
            ? warrantyTVManufactuers(data, next)
            : chargeableTVManufactuers(data, next)}
          <button onClick={previous}>Previous</button>
        </>
      );
    default:
      return (
        <>
          {otherManufacturer(data, next)}
          <button onClick={previous}>Previous</button>
        </>
      );
  }
}

export default Manufacturer;
