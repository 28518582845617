import React from "react";

function ProductDetails(props) {
  const { data, handleChange, next, previous } = props;
  return (
    <form>
      <div className="row">
        <label htmlFor="name">Make:</label>
        <input
          type="text"
          name="firstName"
          readOnly={true}
          value={data.manufacturer}
          onChange={handleChange}
        />
      </div>
      <div className="row">
        <label htmlFor="name">Model No:</label>
        <input
          type="text"
          name="model"
          required={true}
          value={data.model}
          onChange={handleChange}
        />
      </div>
      <div className="row">
        <label htmlFor="email">Serial No:</label>
        <input
          type="text"
          name="serialNo"
          required={true}
          value={data.serialNo}
          onChange={handleChange}
        />
      </div>

      <div className="row">
        <label htmlFor="retailer">Details of Retailer:</label>
        <input
          type="text"
          name="retailer"
          required={true}
          value={data.retailer}
          onChange={handleChange}
        />
      </div>

      <div className="row">
        <label htmlFor="dop">Date of Purchase:</label>
        <input
          type="date"
          name="dop"
          required={true}
          value={data.dop}
          onChange={handleChange}
        />
      </div>

      <h2>Accessories</h2>

      <button onClick={previous}>previous</button>

      {data.model !== "" && data.serialNo !== "" ? (
        <button onClick={next}>Next</button>
      ) : (
        <>
          <>Required field missing</>
        </>
      )}
    </form>
  );
}

export default ProductDetails;
