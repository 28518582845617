import React from "react";
const Submit = (props) => {
  const { data, back, handleChange } = props;

  return (
    <div>
      <h2>Summary</h2>
      <div className="row">
        <p>
          <strong>Full Name: </strong>
          {data.firstName + " " + data.lastName}
        </p>
        <p>
          <strong>Email: </strong>
          {data.email}
        </p>
        <p>
          <strong>Telephone No: </strong>
          {data.phoneNo}
        </p>
      </div>
      <div className="row">
        <h3>Address</h3>
        <p>
          <strong>Address Line 1: </strong>
          {data.addressLine1}
        </p>
        <p>
          <strong>Address Line 2: </strong>
          {data.addressLine2}
        </p>
        <p>
          <strong>Town / City: </strong>
          {data.addressCity}
        </p>
        <p>
          <strong>Post Code: </strong>
          {data.addressPostCode}
        </p>
      </div>

      <div className="row">
        <h3>Product Details</h3>
        <p>
          <strong>Manufacturer: </strong>
          {data.manufacturer}
        </p>
        <p>
          <strong>Model: </strong>
          {data.model}
        </p>
        <p>
          <strong>Serial No: </strong>
          {data.serialNo}
        </p>
        <p>
          <strong>Retailer: </strong>
          {data.phoneNo}
        </p>
        <p>
          <strong>Date of Purchase: </strong>
          {data.dop}
        </p>
      </div>

      <div className="row">
        <h3>Fault Details</h3>
        <p>
          <strong>Fault Type: </strong>
          {data.faultType}
        </p>
        <p>
          <strong>Fault Description: </strong>
          {data.fault}
        </p>
      </div>
      <button type="submit">Submit</button>
      <button onClick={back}>previous </button>
    </div>
  );
};
export default Submit;
