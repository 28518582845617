import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ROOT_API = process.env.REACT_APP_API_URL;

function Summary() {
  const [errorMsg, setErrorMsg] = useState("");

  const [noCollections, setNoCollections] = useState(0);
  const [noSpares, setNoSpares] = useState(0);
  const [noInspections, setNoInspections] = useState(0);
  const [parts, setParts] = useState([]);
  const [isLoadingJob, setIsLoading] = useState(true);

  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();

  const [date] = useState(cDay + "/" + cMonth + "/" + cYear);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = await fetch(`${ROOT_API}/summary`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON) {
          setNoCollections(dataJSON.data.collect);
          setNoSpares(dataJSON.data.partsfit);
          setNoInspections(dataJSON.data.inspect);
          setParts(dataJSON.data.spares);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(error.message);
      }
    };
    fetchJob();
  }, [isLoadingJob]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Daily Summary</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        {isLoadingJob ? (
          <div>
            <div class="container">
              <div className="col-sm-6 col-12">
                <h2>Today's Date: {date}</h2>
                <p>
                  <b>Collections: </b>
                </p>
                <p>
                  <b>Spares: </b>
                </p>
                <p>
                  <b>Inspections: </b>
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <h2>Parts for Today</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Part Name</th>
                        <th>Part ID</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody className="placeholder-table">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {errorMsg}
          </div>
        ) : (
          <div>
            <div class="container">
              <div className="col-sm-6 col-12">
                <h2>Today's Date: {date}</h2>
                <p>
                  <b>Collections: {noCollections}</b>
                </p>
                <p>
                  <b>Spares: {noSpares}</b>
                </p>
                <p>
                  <b>Inspections: {noInspections}</b>
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <h2>Parts for Today</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Part Name</th>
                        <th>Part ID</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parts.map((item) => {
                        return (
                          <tr>
                            <td>{item["part-name"]}</td>
                            <td>{item["part-id"]}</td>
                            <td>{item.role}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {errorMsg}
            <Link to="/diary" className="col-sm-3 btn btn-primary">
              Go to Diary
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Summary;
