import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PortalHTML from "./Portal";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const ROOT_API = process.env.REACT_APP_API_URL;
const STRIPE_API = process.env.REACT_APP_STRIPE_SECRET;

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
    </form>
  );
};

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

function Job(props) {
  const [errorMsg, setErrorMsg] = useState("Loading...");

  const [isLoadingJob, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  var { id, postcode } = useParams();

  const options = {
    // passing the client secret obtained from the server
    clientSecret: STRIPE_API,
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = fetch(`${ROOT_API}/jobs/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          // setIsLoading(false);
          setData(dataJSON.data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMsg(error);
      }
    };
    fetchJob();
  }, [id, isLoadingJob]);

  return (
    <>
      {PortalHTML(
        <>
          <div className="px-4 py-5">
            <div className="row col-12 center">
              <div className="center text-center">
                <h1>Superbase Repair Portal</h1>
              </div>
              <hr className="" />
            </div>
            <div className="container">
              {isLoadingJob ? (
                <div>{errorMsg}</div>
              ) : (
                <div
                  className="columns"
                  style={{
                    display: "flex",
                    padding: "0 40px",
                    marginLeft: "-40px",
                  }}
                >
                  <section>
                    <h3>Policy And Customer Info</h3>
                    <p>
                      <strong>Policy Number:</strong> {data["policy-no"]}
                    </p>
                    <p>
                      <strong>Repair No.</strong> {data["repair-reference"]}
                    </p>
                    <p>
                      <strong>Policy Expiry:</strong> {data["policy-expiry"]}
                    </p>
                    <h3>Product and Fault Info</h3>
                    <p>
                      <strong>Date Filed</strong> {data["file-date"]}
                    </p>
                    <p>
                      <strong>Manufacturer</strong> {data["manufacturer"]}
                    </p>
                    <p>
                      <strong>Product Type</strong> {data["product-type"]}
                    </p>
                    <p>
                      <strong>Model Number</strong> {data["model-number"]}
                    </p>
                    <p>
                      <strong>Fault</strong> {data["fault"]}
                    </p>
                  </section>
                  <section>
                    <h3>Status</h3>
                    <p>
                      <strong>Post Code: </strong>
                      {data["post-code"]}
                    </p>
                    <p>
                      <strong>Job Status</strong> {data["job-status"]}
                    </p>
                    <p>
                      <strong>Repair Date</strong> {data["repair-date"]}
                    </p>
                    <p>
                      <strong>Dispatch Date</strong> {data["despatch-date"]}
                    </p>
                    <p>
                      <strong>
                        Additional Information: {data["spare-text3"]}
                      </strong>
                    </p>
                  </section>
                </div>
              )}
            </div>

            <form className="">
              <h2>Leave a comment</h2>
              <section style={{ display: "flex" }}>
                <label htmlFor="fault">Comment: </label>
                <textarea name="fault" required={true} maxLength={1000} />

                <button type="submit">Submit</button>
              </section>
              <p>
                <small>1000 Character Limit</small>
              </p>
            </form>

            <div className="mt-2">
              <button>Accept Estimate</button>
              <button>Refuse Estimate (£25)</button>

              <div className="container col-4 mt-2">
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>

                <img src="frontend\src\img\Powered by Stripe - blurple.svg"></img>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Job;
