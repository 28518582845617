import React, { useEffect } from "react";
import RequestForm from "../../_components/RequestForm/RequestForm";
import PortalHTML from "./Portal";

function RepairHomePage() {
  // API key of the google map
  const GOOGLE_MAP_API_KEY = "AIzaSyDd4a4FyYkL9zHbOFACoBKd6RJl81YGEEM";

  // load google map script
  function loadGoogleMapScript(callback) {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  }

  useEffect(() => {
    loadGoogleMapScript();
  }, []);

  return (
    <>
      {PortalHTML(
        <div className="container">
          <h1>Customer Portal</h1>

          <RequestForm />
        </div>
      )}
    </>
  );
}

export default RepairHomePage;
