import React from "react";

function FaultDetails(props) {
  const { data, handleChange, next, previous } = props;
  return (
    <form>
      <div className="row">
        <label htmlFor="faultType">Fault Type:</label>
        <select name="faultType" value={data.faultType} onChange={handleChange}>
          <option value="">Select A Fault</option>
          <option value="NOWORK1">Not Working</option>
          <option value="NOWORK2">Not Working</option>
          <option value="NOWORK3">Not Working</option>
          <option value="NOWORK4">Not Working</option>
          <option value="other">Other</option>
        </select>
        {data.faultType === "other" && (
          <div className="row">
            <label htmlFor="other">Please State</label>
            <input name="other" />
          </div>
        )}
      </div>
      <div className="row">
        <label htmlFor="fault">Fault Description:</label>
        <textarea
          name="fault"
          required={true}
          value={data.fault}
          onChange={handleChange}
          maxLength={1000}
        />
        <p>
          <small>1000 Character Limit</small>
        </p>
      </div>

      <button onClick={previous}>previous</button>

      {data.faultType !== "" && data.fault !== "" ? (
        <button onClick={next}>Next</button>
      ) : (
        <p>Required field missing</p>
      )}
    </form>
  );
}

export default FaultDetails;
