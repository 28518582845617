import React from "react";

function Head() {
  return (
    <head>
      <meta charSet="UTF-8" />
      <link rel="profile" href="https://gmpg.org/xfn/11" />
      <script
        type="text/javascript"
        async=""
        src="//www.dkavs.co.uk/wp-content/uploads/matomo/matomo.js"
      ></script>
      <title>About DKAVS Electronics Repair Centre | DK AVS Ltd</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="description"
        content="The United Kingdom’s leading Repair Centre. We’ve been operating for over 30 years, repairing Digital Cameras, Camcorders, TV’s and much more"
      />
      <meta
        property="og:image"
        content="https://www.dkavs.co.uk/wp-content/uploads/2021/06/ast-1158227.jpg.pub_.crop_.pc_.thumb_.640.1200.jpg"
      />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="About DKAVS Electronics Repair Centre"
      />
      <meta
        property="og:description"
        content="The United Kingdom’s leading Repair Centre. We’ve been operating for over 30 years, repairing Digital Cameras, Camcorders, TV’s and much more"
      />
      <meta property="og:url" content="https://www.dkavs.co.uk/about-us/" />
      <meta property="og:site_name" content="DK AVS Ltd" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="About DKAVS Electronics Repair Centre"
      />
      <meta
        name="twitter:description"
        content="The United Kingdom’s leading Repair Centre. We’ve been operating for over 30 years, repairing Digital Cameras, Camcorders, TV’s and much more"
      />
      <meta
        name="twitter:image"
        content="https://www.dkavs.co.uk/wp-content/uploads/2021/06/ast-1158227.jpg.pub_.crop_.pc_.thumb_.640.1200.jpg"
      />
      <link rel="canonical" href="https://repair.dkavs.co.uk" />
      <link
        rel="stylesheet"
        href="https://www.dkavs.co.uk/wp-content/cache/minify/a5ff7.css"
        media="all"
      />
      <link
        rel="stylesheet"
        href="https://www.dkavs.co.uk/wp-content/cache/minify/9c881.css"
        media="all"
      />
      <link rel="https://api.w.org/" href="https://www.dkavs.co.uk/wp-json/" />
      <link
        rel="alternate"
        type="application/json"
        href="https://www.dkavs.co.uk/wp-json/wp/v2/pages/1077"
      />
      <link
        rel="EditURI"
        type="application/rsd+xml"
        title="RSD"
        href="https://www.dkavs.co.uk/xmlrpc.php?rsd"
      />
      <link
        rel="wlwmanifest"
        type="application/wlwmanifest+xml"
        href="https://www.dkavs.co.uk/wp-includes/wlwmanifest.xml"
      />
      <link
        rel="alternate"
        type="application/json+oembed"
        href="https://www.dkavs.co.uk/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.dkavs.co.uk%2Fabout-us%2F"
      />
      <link
        rel="alternate"
        type="text/xml+oembed"
        href="https://www.dkavs.co.uk/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.dkavs.co.uk%2Fabout-us%2F&amp;format=xml"
      />{" "}
      <script>
        var ajaxurl='https://www.dkavs.co.uk/wp-admin/admin-ajax.php';
      </script>{" "}
      <link
        rel="icon"
        href="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new-270x270.png"
      />
    </head>
  );
}

function NavBar() {
  return (
    <header
      className="site-header has-inline-mobile-toggle"
      id="masthead"
      aria-label="Site"
      itemType="https://schema.org/WPHeader"
      itemScope=""
    >
      <div className="inside-header grid-container">
        <div className="site-logo">
          <a href="https://www.dkavs.co.uk/" title="DK AVS Ltd" rel="home">
            <img
              className="header-image is-logo-image lazy loaded"
              alt="DK AVS Ltd"
              src="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new.png"
              data-src="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new.png"
              title="DK AVS Ltd"
              data-was-processed="true"
              width="512"
              height="512"
            />
          </a>
        </div>
        <nav
          className="main-navigation mobile-menu-control-wrapper"
          id="mobile-menu-control-wrapper"
          aria-label="Mobile Toggle"
        >
          <button
            data-nav="site-navigation"
            className="menu-toggle"
            aria-controls="primary-menu"
            aria-expanded="false"
          >
            <span className="gp-icon icon-menu-bars">
              <svg
                viewBox="0 0 512 512"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
              >
                <path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z"></path>
              </svg>
              <svg
                viewBox="0 0 512 512"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
              >
                <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z"></path>
              </svg>
            </span>
            <span className="screen-reader-text">Menu</span>{" "}
          </button>
        </nav>
        <nav
          className="main-navigation sub-menu-right"
          id="site-navigation"
          aria-label="Primary"
          itemType="https://schema.org/SiteNavigationElement"
          itemScope=""
        >
          <div className="inside-navigation">
            <button
              className="menu-toggle"
              aria-controls="primary-menu"
              aria-expanded="false"
            >
              <span className="gp-icon icon-menu-bars">
                <svg
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                >
                  <path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z"></path>
                </svg>
                <svg
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                >
                  <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z"></path>
                </svg>
              </span>
              <span className="mobile-menu">Menu</span>{" "}
            </button>
            <div id="primary-menu" className="main-nav">
              <ul id="menu-main-menu" className=" menu sf-menu">
                <li
                  id="menu-item-1057"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1057"
                >
                  <a href="https://www.dkavs.co.uk/">Home</a>
                </li>
                <li
                  id="menu-item-1956"
                  className="menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-1956"
                >
                  <a href="https://www.dkavs.co.uk/services/">
                    Services
                    <span role="presentation" className="dropdown-menu-toggle">
                      <span className="gp-icon icon-arrow">
                        <svg
                          viewBox="0 0 330 512"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                        >
                          <path d="M305.913 197.085c0 2.266-1.133 4.815-2.833 6.514L171.087 335.593c-1.7 1.7-4.249 2.832-6.515 2.832s-4.815-1.133-6.515-2.832L26.064 203.599c-1.7-1.7-2.832-4.248-2.832-6.514s1.132-4.816 2.832-6.515l14.162-14.163c1.7-1.699 3.966-2.832 6.515-2.832 2.266 0 4.815 1.133 6.515 2.832l111.316 111.317 111.316-111.317c1.7-1.699 4.249-2.832 6.515-2.832s4.815 1.133 6.515 2.832l14.162 14.163c1.7 1.7 2.833 4.249 2.833 6.515z"></path>
                        </svg>
                      </span>
                    </span>
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-1958"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1958"
                    >
                      <a href="https://www.dkavs.co.uk/services/tv-repair/">
                        TV Repair
                      </a>
                    </li>
                    <li
                      id="menu-item-1959"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1959"
                    >
                      <a href="https://www.dkavs.co.uk/services/digital-camera-repair/">
                        Digital Camera Repair
                        <span
                          role="presentation"
                          className="dropdown-menu-toggle"
                        >
                          <span className="gp-icon icon-arrow-right">
                            <svg
                              viewBox="0 0 192 512"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              strokeLinejoin="round"
                              strokeMiterlimit="1.414"
                            >
                              <path
                                d="M178.425 256.001c0 2.266-1.133 4.815-2.832 6.515L43.599 394.509c-1.7 1.7-4.248 2.833-6.514 2.833s-4.816-1.133-6.515-2.833l-14.163-14.162c-1.699-1.7-2.832-3.966-2.832-6.515 0-2.266 1.133-4.815 2.832-6.515l111.317-111.316L16.407 144.685c-1.699-1.7-2.832-4.249-2.832-6.515s1.133-4.815 2.832-6.515l14.163-14.162c1.7-1.7 4.249-2.833 6.515-2.833s4.815 1.133 6.514 2.833l131.994 131.993c1.7 1.7 2.832 4.249 2.832 6.515z"
                                fillRule="nonzero"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-2749"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2749"
                        >
                          <a href="https://www.dkavs.co.uk/services/lumix-camera-repair/">
                            LUMIX Camera Repair
                          </a>
                        </li>
                        <li
                          id="menu-item-2566"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2566"
                        >
                          <a href="https://www.dkavs.co.uk/lumix-lens-repair/">
                            LUMIX Lens Repair
                          </a>
                        </li>
                        <li
                          id="menu-item-2753"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2753"
                        >
                          <a href="https://www.dkavs.co.uk/services/sony-camera-repair/">
                            Sony Camera Repair
                          </a>
                        </li>
                        <li
                          id="menu-item-2752"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2752"
                        >
                          <a href="https://www.dkavs.co.uk/services/canon-camera-repair/">
                            Canon Camera Repair
                          </a>
                        </li>
                        <li
                          id="menu-item-2751"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2751"
                        >
                          <a href="https://www.dkavs.co.uk/services/nikon-camera-repair/">
                            Nikon Camera Repair
                          </a>
                        </li>
                        <li
                          id="menu-item-2750"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2750"
                        >
                          <a href="https://www.dkavs.co.uk/services/fujifilm-camera-repair/">
                            Fujifilm Camera Repair
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-1957"
                      className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-63 current_page_item menu-item-1957"
                    >
                      <a
                        href="https://www.dkavs.co.uk/services/cinema-hifi-audio/"
                        aria-current="page"
                      >
                        Audio Equipment Repair
                      </a>
                    </li>
                    <li
                      id="menu-item-2758"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2758"
                    >
                      <a href="https://www.dkavs.co.uk/services/gadget-repairs/">
                        Gadget Repair
                      </a>
                    </li>
                    <li
                      id="menu-item-2013"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2013"
                    >
                      <a href="https://www.dkavs.co.uk/domestic-general/">
                        Domestic and General Camera &amp; TV Repair
                      </a>
                    </li>
                    <li
                      id="menu-item-1960"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1960"
                    >
                      <a href="https://www.dkavs.co.uk/services/send-device/">
                        How To Send Your Device To Us
                      </a>
                    </li>
                    <li
                      id="menu-item-2870"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2870"
                    >
                      <a href="https://www.dkavs.co.uk/create-repair-request/">
                        Request Repair
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-1245"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1245"
                >
                  <a href="https://www.dkavs.co.uk/about-us/">About Us</a>
                </li>
                <li
                  id="menu-item-532"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-532"
                >
                  <a href="https://www.dkavs.co.uk/contact-us/">Contact Us</a>
                </li>
                <li
                  id="menu-item-147"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-147"
                >
                  <a href="https://www.dkavs.co.uk/customer-feedback/">
                    Feedback
                  </a>
                </li>
                <li
                  id="menu-item-2692"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2692"
                >
                  <a href="https://www.dkavs.co.uk/frequently-asked-questions/">
                    FAQ
                  </a>
                </li>
                <li
                  id="menu-item-2862"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2862"
                >
                  <a href="https://repair.dkavs.co.uk/">Track Repair</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <>
      <div className="site-footer footer-bar-active footer-bar-align-right">
        <div
          id="footer-widgets"
          className="site footer-widgets"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="footer-widgets-container grid-container">
            <div className="inside-footer-widgets">
              <div className="footer-widget-1">
                <aside
                  id="block-79"
                  className="widget inner-padding widget_block"
                >
                  <h2 className="has-text-align-left">Our Services</h2>
                </aside>
                <aside
                  id="block-115"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    <a href="https://www.dkavs.co.uk/services/digital-camera-repair/">
                      Digital Camera Repair
                    </a>
                  </p>
                </aside>
                <aside
                  id="block-108"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    <a href="https://www.dkavs.co.uk/services/tv-repair/">
                      TV Repair
                    </a>
                  </p>
                </aside>
                <aside
                  id="block-110"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    <a href="https://www.dkavs.co.uk/services/cinema-hifi-audio/">
                      Audio Equipment Repair
                    </a>
                  </p>
                </aside>
                <aside
                  id="block-112"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    <a href="https://www.dkavs.co.uk/services/gadget-repairs/#drones">
                      Drone Repair
                    </a>
                  </p>
                </aside>
                <aside
                  id="block-113"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    <a href="https://www.dkavs.co.uk/lumix-lens-repair/">
                      LUMIX Lens Repair
                    </a>
                  </p>
                </aside>
              </div>
              <div className="footer-widget-2">
                <aside
                  id="block-96"
                  className="widget inner-padding widget_block"
                >
                  <h2>Contact Us</h2>
                </aside>
                <aside
                  id="block-95"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>
                    DK Audio Visual Services
                    <br />
                    Unit 17 Gatwick Metro Centre
                    <br />
                    Balcombe Road
                    <br />
                    Horley
                    <br />
                    Surrey
                    <br />
                    RH6 9GA
                  </p>
                </aside>
                <aside
                  id="block-76"
                  className="widget inner-padding widget_block widget_text"
                >
                  <p>Tel: 01293 406854</p>
                </aside>
              </div>
              <div className="footer-widget-3">
                <aside
                  id="block-120"
                  className="widget inner-padding widget_block"
                >
                  <h2 className="widget-title">Social Media</h2>
                  <div className="wp-widget-group__inner-blocks">
                    <div className="wp-container-62310048913f6 wp-block-group">
                      <div className="wp-block-group__inner-container">
                        <ul className="wp-container-6231004890b8e wp-block-social-links aligncenter has-large-icon-size is-style-logos-only">
                          <li className="wp-social-link wp-social-link-facebook wp-block-social-link">
                            <a
                              href="https://www.facebook.com/dkavservice"
                              aria-label="Facebook: https://www.facebook.com/dkavservice"
                              className="wp-block-social-link-anchor"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="wp-social-link wp-social-link-twitter wp-block-social-link">
                            <a
                              href="https://twitter.com/dk_audio_visual"
                              aria-label="Twitter Profile"
                              className="wp-block-social-link-anchor"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M22.23,5.924c-0.736,0.326-1.527,0.547-2.357,0.646c0.847-0.508,1.498-1.312,1.804-2.27 c-0.793,0.47-1.671,0.812-2.606,0.996C18.324,4.498,17.257,4,16.077,4c-2.266,0-4.103,1.837-4.103,4.103 c0,0.322,0.036,0.635,0.106,0.935C8.67,8.867,5.647,7.234,3.623,4.751C3.27,5.357,3.067,6.062,3.067,6.814 c0,1.424,0.724,2.679,1.825,3.415c-0.673-0.021-1.305-0.206-1.859-0.513c0,0.017,0,0.034,0,0.052c0,1.988,1.414,3.647,3.292,4.023 c-0.344,0.094-0.707,0.144-1.081,0.144c-0.264,0-0.521-0.026-0.772-0.074c0.522,1.63,2.038,2.816,3.833,2.85 c-1.404,1.1-3.174,1.756-5.096,1.756c-0.331,0-0.658-0.019-0.979-0.057c1.816,1.164,3.973,1.843,6.29,1.843 c7.547,0,11.675-6.252,11.675-11.675c0-0.178-0.004-0.355-0.012-0.531C20.985,7.47,21.68,6.747,22.23,5.924z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="wp-social-link wp-social-link-instagram wp-block-social-link">
                            <a
                              href="https://www.instagram.com/dkav.s/"
                              aria-label="Instagram: https://www.instagram.com/dkav.s/"
                              className="wp-block-social-link-anchor"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M12,4.622c2.403,0,2.688,0.009,3.637,0.052c0.877,0.04,1.354,0.187,1.671,0.31c0.42,0.163,0.72,0.358,1.035,0.673 c0.315,0.315,0.51,0.615,0.673,1.035c0.123,0.317,0.27,0.794,0.31,1.671c0.043,0.949,0.052,1.234,0.052,3.637 s-0.009,2.688-0.052,3.637c-0.04,0.877-0.187,1.354-0.31,1.671c-0.163,0.42-0.358,0.72-0.673,1.035 c-0.315,0.315-0.615,0.51-1.035,0.673c-0.317,0.123-0.794,0.27-1.671,0.31c-0.949,0.043-1.233,0.052-3.637,0.052 s-2.688-0.009-3.637-0.052c-0.877-0.04-1.354-0.187-1.671-0.31c-0.42-0.163-0.72-0.358-1.035-0.673 c-0.315-0.315-0.51-0.615-0.673-1.035c-0.123-0.317-0.27-0.794-0.31-1.671C4.631,14.688,4.622,14.403,4.622,12 s0.009-2.688,0.052-3.637c0.04-0.877,0.187-1.354,0.31-1.671c0.163-0.42,0.358-0.72,0.673-1.035 c0.315-0.315,0.615-0.51,1.035-0.673c0.317-0.123,0.794-0.27,1.671-0.31C9.312,4.631,9.597,4.622,12,4.622 M12,3 C9.556,3,9.249,3.01,8.289,3.054C7.331,3.098,6.677,3.25,6.105,3.472C5.513,3.702,5.011,4.01,4.511,4.511 c-0.5,0.5-0.808,1.002-1.038,1.594C3.25,6.677,3.098,7.331,3.054,8.289C3.01,9.249,3,9.556,3,12c0,2.444,0.01,2.751,0.054,3.711 c0.044,0.958,0.196,1.612,0.418,2.185c0.23,0.592,0.538,1.094,1.038,1.594c0.5,0.5,1.002,0.808,1.594,1.038 c0.572,0.222,1.227,0.375,2.185,0.418C9.249,20.99,9.556,21,12,21s2.751-0.01,3.711-0.054c0.958-0.044,1.612-0.196,2.185-0.418 c0.592-0.23,1.094-0.538,1.594-1.038c0.5-0.5,0.808-1.002,1.038-1.594c0.222-0.572,0.375-1.227,0.418-2.185 C20.99,14.751,21,14.444,21,12s-0.01-2.751-0.054-3.711c-0.044-0.958-0.196-1.612-0.418-2.185c-0.23-0.592-0.538-1.094-1.038-1.594 c-0.5-0.5-1.002-0.808-1.594-1.038c-0.572-0.222-1.227-0.375-2.185-0.418C14.751,3.01,14.444,3,12,3L12,3z M12,7.378 c-2.552,0-4.622,2.069-4.622,4.622S9.448,16.622,12,16.622s4.622-2.069,4.622-4.622S14.552,7.378,12,7.378z M12,15 c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S13.657,15,12,15z M16.804,6.116c-0.596,0-1.08,0.484-1.08,1.08 s0.484,1.08,1.08,1.08c0.596,0,1.08-0.484,1.08-1.08S17.401,6.116,16.804,6.116z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="wp-social-link wp-social-link-linkedin wp-block-social-link">
                            <a
                              href="http://linkedin.com/company/dkavs"
                              aria-label="LinkedIn Profile"
                              className="wp-block-social-link-anchor"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M19.7,3H4.3C3.582,3,3,3.582,3,4.3v15.4C3,20.418,3.582,21,4.3,21h15.4c0.718,0,1.3-0.582,1.3-1.3V4.3 C21,3.582,20.418,3,19.7,3z M8.339,18.338H5.667v-8.59h2.672V18.338z M7.004,8.574c-0.857,0-1.549-0.694-1.549-1.548 c0-0.855,0.691-1.548,1.549-1.548c0.854,0,1.547,0.694,1.547,1.548C8.551,7.881,7.858,8.574,7.004,8.574z M18.339,18.338h-2.669 v-4.177c0-0.996-0.017-2.278-1.387-2.278c-1.389,0-1.601,1.086-1.601,2.206v4.249h-2.667v-8.59h2.559v1.174h0.037 c0.356-0.675,1.227-1.387,2.526-1.387c2.703,0,3.203,1.779,3.203,4.092V18.338z"></path>
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>
                <aside
                  id="block-122"
                  className="widget inner-padding widget_block"
                >
                  <div className="wp-container-6231004891e0a wp-block-buttons">
                    <div className="wp-block-button has-custom-width wp-block-button__width-50 is-style-outline">
                      <a
                        className="wp-block-button__link has-base-3-color has-accent-background-color has-text-color has-background"
                        href="https://uk.trustpilot.com/review/www.dkavs.co.uk"
                        style={{ borderRadius: "0px" }}
                      >
                        Trustpilot Reviews
                      </a>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <footer
          className="site-info"
          aria-label="Site"
          itemType="https://schema.org/WPFooter"
          itemScope=""
        >
          <div className="inside-site-info grid-container">
            <div className="footer-bar">
              <aside
                id="nav_menu-7"
                className="widget inner-padding widget_nav_menu"
              >
                <div className="menu-footer-menu-container">
                  <ul id="menu-footer-menu" className="menu">
                    <li
                      id="menu-item-228"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-228"
                    >
                      <a href="https://www.dkavs.co.uk/privacy-policy/">
                        Privacy Policy
                      </a>
                    </li>
                    <li
                      id="menu-item-255"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-255"
                    >
                      <a href="https://www.dkavs.co.uk/terms-conditions/">
                        Terms &amp; Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="copyright-bar">
              <span className="copyright">© 2022 DK AVS Ltd</span>
            </div>
          </div>
        </footer>
      </div>
      <script
        async=""
        src="https://www.dkavs.co.uk/wp-content/plugins/w3-total-cache/pub/js/lazyload.min.js"
      ></script>
    </>
  );
}

function PortalHTML(html) {
  return (
    <>
      <Head />

      <body
        className="page-template-default page page-id-63 page-child parent-pageid-1805 wp-custom-logo wp-embed-responsive no-sidebar nav-float-right separate-containers header-aligned-left dropdown-hover elementor-beta elementor-default elementor-kit-658"
        itemType="https://schema.org/WebPage"
        itemScope=""
        style={{ backgroundColor: "#efefef" }}
      >
        <a
          className="screen-reader-text skip-link"
          href="#content"
          title="Skip to content"
        >
          Skip to content
        </a>
        <NavBar />
        <div
          className="container"
          style={{
            backgroundColor: "#fff",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          {html}
        </div>

        <Footer />
      </body>
    </>
  );
}

export default PortalHTML;
