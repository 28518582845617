import React from "react";
import TrackForm from "../../_components/TrackForm";
import PortalHTML from "./Portal";

function RepairHomePage() {
  return (
    <>
      {PortalHTML(
        <>
          <h1>Customer Portal</h1>
          <div
            style={{
              display: "flex",
              padding: "0 40px",
              marginLeft: "-40px",
              textAlign: "center",
            }}
            className="columns"
          >
            <section style={{ flex: "1 1 0" }} className="pt-3 px-4 mb-2">
              <h2>Track Repair</h2>
              <p>
                <img
                  alt=""
                  className="lazy"
                  src="https://www.dkavs.co.uk/wp-content/uploads/2021/12/tvbeats-kRlzDMzj5UM-unsplash-min-scaled-e1640109768632.jpg"
                  width={"330px"}
                  height="200px"
                />
              </p>

              <TrackForm />
            </section>
            <section
              className="place-request pt-3 px-4 mb-2"
              style={{ flex: "1 1 0", textAlign: "center" }}
            >
              <h2>Place a Repair Request</h2>
              <p>
                <img
                  alt=""
                  className="lazy"
                  src="https://www.dkavs.co.uk/wp-content/uploads/2021/12/sharegrid-Y7BSXW12rw0-unsplash-min-scaled-e1640109708728.jpg"
                  width="400px"
                  height="200px"
                />
              </p>
              <a style={{ minWidth: "10rem" }} href="/#/create-request">
                Make A Request
              </a>
            </section>

            <hr
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.25) !important",
                marginTop: "5rem",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              padding: "0 40px",
              marginLeft: "-40px",
              marginTop: "5rem",
            }}
            className="columns"
          >
            <h2> Customer Service Questions</h2>
            <ul>
              <li style={{ marginLeft: "0" }}>
                <strong>
                  Can you give me a quote for the repair of my camera before it
                  has been inspected by an engineer?
                </strong>
                <br />
                The camera would need to be thoroughly inspected both internally
                and externally before a fair estimation of the cost to repair
                the camera can be established. Once repair charges have been
                estimated they would be detailed to you in a letter or by email.
              </li>
              <li style={{ marginLeft: "0" }}>
                <strong>
                  Where can I check or download software for my Lumix camera?
                </strong>
                <br />
                Please visit:
                <a href="https://av.jpn.support.panasonic.com/support/global/cs/dsc/download/index4.html">
                  Panasonic support page
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default RepairHomePage;
