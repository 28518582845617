// https://www.cluemediator.com/google-place-autocomplete-in-reactjs

import React, { useRef, useState, useEffect } from "react";

const PlacesAutocomplete = (props) => {
  const placeInputRef = useRef(null);

  const { data, handleChange } = props;

  useEffect(() => {
    initPlaceAPI();
  }, []);

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    );
    autocomplete.setComponentRestrictions({ country: ["GB"] });
    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              data.addressLine1 = `${component.short_name} ${data.addressLine1}`;
              break;
            }

            case "route": {
              data.addressLine1 += component.short_name;
              break;
            }

            case "postal_code": {
              data.addressPostCode = `${component.long_name}${data.addressPostCode}`;
              break;
            }

            case "postal_code_suffix": {
              data.addressPostCode = `${data.addressPostCode}-${component.long_name}`;
              break;
            }
            case "postal_town":
              data.addressCity = component.long_name;
              break;
          }
        }
      }
    );
  };

  return (
    <>
      <input type="text" ref={placeInputRef} />
      {data.addressLine1 !== "" && (
        <div className="row">
          <label htmlFor="email">Address Line 1:</label>
          <input
            type="text"
            name="addressLine1"
            value={data.addressLine1}
            onChange={handleChange}
          />
          <label htmlFor="email">Address Line 2:</label>
          <input
            type="text"
            name="addressLine2"
            value={data.addressLine2}
            onChange={handleChange}
            autoFocus={true}
          />
          <label htmlFor="email">City:</label>
          <input
            type="text"
            name="addressCity"
            value={data.addressCity}
            onChange={handleChange}
          />
          <label htmlFor="email">Post Code:</label>
          <input
            type="text"
            name="addressPostCode"
            value={data.addressPostCode}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
};

export default PlacesAutocomplete;
