import React from "react";

function WarrantyStep(props) {
  const { data, next } = props;

  return (
    <div className="portal-container">
      <button
        className="col-12 col-sm-2"
        name="warranty"
        onClick={() => {
          data.warranty = true;
          next();
        }}
      >
        Warranty
      </button>
      <button
        className="col-12 col-sm-2"
        name="warranty"
        onClick={() => {
          data.warranty = false;
          next();
        }}
      >
        Chargeable
      </button>
    </div>
  );
}

export default WarrantyStep;
